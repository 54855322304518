import {
  type GetCompanyListResponse,
  type ResultGetCompanyList,
} from '../../repositories/companyRepository';

export function getCompaniesFromPages(
  data: ResultGetCompanyList | undefined,
): GetCompanyListResponse | undefined {
  if (!data?.ok) return undefined;
  return data.value;
}
