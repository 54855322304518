import {Box} from '@mantine/core';
import Image from 'next/image';
import {GoogleAdBannerName} from '../../../../config/constants/googleAdBanner';
import {BANNER_EVENT_IMAGE} from '../../../../config/constants/img';
import {GoogleAdBanner} from '../../../../shared/components/Banner/GoogleAdBanner';
import {useGoogleAdBanner} from '../../../../shared/services/useGoogleAdBanner';
import {RightSideCalender} from '../../../recruitingInfo/components/Container/Presenter/RightSideCalendar';
import {FollowCompanyRanking} from '../../../../shared/components/Sidebar/FollowCompanyRanking';

type Props = {
  accessToken: string;
};

export const LoggedInTopRightColumn: React.FC<Props> = ({accessToken}) => {
  // DFPバナーのセット
  const googleAdBannerNames = [
    GoogleAdBannerName.RICH_DESKTOP_HEADER,
    GoogleAdBannerName.B_RECTANGLE_BOTTOM,
    GoogleAdBannerName.D_FEED_LEFT_SMALL,
    GoogleAdBannerName.B_RECTANGLE,
  ];
  useGoogleAdBanner(googleAdBannerNames, accessToken);
  return (
    <Box className="w-[320px] pl-16 pt-8">
      <Box className="top-8 flex flex-col gap-y-16">
        <GoogleAdBanner googleAdBannerName={GoogleAdBannerName.B_RECTANGLE} />
        <FollowCompanyRanking accessToken={accessToken} />
        <a
          href="https://event.gaishishukatsu.com/"
          target="_blank"
          rel="noopener"
        >
          <Image
            src={BANNER_EVENT_IMAGE}
            alt="開催イベント一覧"
            width={300}
            height={80}
          />
        </a>
        <RightSideCalender accessToken={accessToken} />
        <GoogleAdBanner
          googleAdBannerName={GoogleAdBannerName.B_RECTANGLE_BOTTOM}
        />
      </Box>
    </Box>
  );
};
