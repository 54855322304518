import {MdWarning} from 'react-icons/md';
import {
  maintenanceStartTime,
  maintenanceEndTime,
} from '../../../config/constants/maintenance';

export const MaintenanceTop = () => {
  const maintenanceStartTimeHours = maintenanceStartTime.getHours();
  const maintenanceEndTimeMonth = maintenanceEndTime.getMonth() + 1;
  const maintenanceEndTimeDay = maintenanceEndTime.getDate();
  const maintenanceEndTimeHours = maintenanceEndTime.getHours();

  return (
    <div className="w-[1200px] mx-auto mb-12 py-12 bg-white text-red-500 text-center">
      <MdWarning size={18} className="inline-block relative" />
      <span className="ml-2">
        【メンテナンス】 {maintenanceEndTimeMonth}/{maintenanceEndTimeDay}&nbsp;
        {maintenanceStartTimeHours}:00~{maintenanceEndTimeHours}
        :00の間、一時的にサービスが利用できなくなる可能性があります。
      </span>
    </div>
  );
};
