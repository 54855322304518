import {Box, Card} from '@mantine/core';
import {WhiteBgButton} from '@packages/ui';
import {useRouter} from 'next/router';
import React, {useState, useEffect} from 'react';
import {MdArrowLeft, MdArrowRight, MdArrowForwardIos} from 'react-icons/md';
import {COLOR_BLACK_2} from '../../../config/constants/colorCodes';
import {
  TAB_LABEL_DAILY,
  TAB_LABEL_WEEKLY,
  TAB_LABEL_MONTHLY,
  MAX_COMPANY_PER_RANKING,
} from '../../../config/constants/followCompanyRanking';
import {COMPANIES} from '../../../config/constants/routes';
import {useGetFollowCompanyRanking} from '../../../core/repositories/companyRepository';
import {CommonLink} from '../CommonLink';
import {formatDateToMonthDay} from '../../services/formatDate';
import {RenderRankingNumber} from './RenderRankingNumber';

type Props = {
  accessToken: string;
};

type ranking = {
  rank: number;
  companyName: string;
  companyID: number;
  diff: number;
  total: number;
};

type currentRanking = {
  ranking: ranking[];
  scopeDate: string;
  currentScope: string;
};

export const FollowCompanyRanking: React.FC<Props> = ({accessToken}) => {
  const {data: rankingData, isLoading: isRankingLoading} =
    useGetFollowCompanyRanking({
      accessToken,
    });

  const [currrentRanking, setCurrentRanking] = useState<currentRanking>({
    ranking: [],
    scopeDate: '',
    currentScope: TAB_LABEL_DAILY,
  });

  useEffect(() => {
    if (!isRankingLoading && rankingData?.ok) {
      setCurrentRanking({
        ranking: rankingData.value.daily.ranking.slice(
          0,
          MAX_COMPANY_PER_RANKING,
        ),
        scopeDate: formatDateToMonthDay(rankingData.value.daily.end),
        currentScope: TAB_LABEL_DAILY,
      });
    }
  }, [rankingData]);

  const changeNext = (currentScope: string) => {
    if (!rankingData?.ok) return;
    switch (currentScope) {
      case TAB_LABEL_DAILY: {
        setCurrentRanking({
          ranking: rankingData.value.weekly.ranking.slice(
            0,
            MAX_COMPANY_PER_RANKING,
          ),
          scopeDate: `${formatDateToMonthDay(
            rankingData.value.weekly.start,
          )} ~ ${formatDateToMonthDay(rankingData.value.weekly.end)}`,
          currentScope: TAB_LABEL_WEEKLY,
        });
        break;
      }

      case TAB_LABEL_WEEKLY: {
        setCurrentRanking({
          ranking: rankingData.value.monthly.ranking.slice(
            0,
            MAX_COMPANY_PER_RANKING,
          ),
          scopeDate: `${formatDateToMonthDay(
            rankingData.value.monthly.start,
          )} ~ ${formatDateToMonthDay(rankingData.value.monthly.end)}`,
          currentScope: TAB_LABEL_MONTHLY,
        });
        break;
      }

      case TAB_LABEL_MONTHLY: {
        setCurrentRanking({
          ranking: rankingData.value.daily.ranking.slice(
            0,
            MAX_COMPANY_PER_RANKING,
          ),
          scopeDate: formatDateToMonthDay(rankingData.value.daily.end),
          currentScope: TAB_LABEL_DAILY,
        });
        break;
      }

      default: {
        break;
      }
    }
  };

  const changePrev = (currentScope: string) => {
    if (!rankingData?.ok) return;
    switch (currentScope) {
      case TAB_LABEL_DAILY: {
        setCurrentRanking({
          ranking: rankingData.value.monthly.ranking.slice(
            0,
            MAX_COMPANY_PER_RANKING,
          ),
          scopeDate: `${formatDateToMonthDay(
            rankingData.value.monthly.start,
          )} ~ ${formatDateToMonthDay(rankingData.value.monthly.end)}`,
          currentScope: TAB_LABEL_MONTHLY,
        });
        break;
      }

      case TAB_LABEL_WEEKLY: {
        setCurrentRanking({
          ranking: rankingData.value.daily.ranking.slice(
            0,
            MAX_COMPANY_PER_RANKING,
          ),
          scopeDate: formatDateToMonthDay(rankingData.value.daily.end),
          currentScope: TAB_LABEL_DAILY,
        });
        break;
      }

      case TAB_LABEL_MONTHLY: {
        setCurrentRanking({
          ranking: rankingData.value.weekly.ranking.slice(
            0,
            MAX_COMPANY_PER_RANKING,
          ),
          scopeDate: `${formatDateToMonthDay(
            rankingData.value.weekly.start,
          )} ~ ${formatDateToMonthDay(rankingData.value.weekly.end)}`,
          currentScope: TAB_LABEL_WEEKLY,
        });
        break;
      }

      default: {
        break;
      }
    }
  };

  const router = useRouter();
  const currnentUrl = decodeURI(router.pathname);

  return (
    <Card className="w-full bg-white px-8 py-16">
      <Box className="max-auto flex flex-col items-center">
        <Box className="flex w-full flex-row items-center justify-between">
          <Box
            onClick={() => {
              changePrev(currrentRanking.currentScope);
            }}
            className="cursor-pointer"
          >
            <MdArrowLeft size={36} />
          </Box>
          <Box className="text-sm  text-black-2">フォロー数ランキング</Box>
          <Box
            onClick={() => {
              changeNext(currrentRanking.currentScope);
            }}
            className="cursor-pointer"
          >
            <MdArrowRight size={36} />
          </Box>
        </Box>
        <Box className="mx-auto text-base font-bold text-black-2">
          {currrentRanking.currentScope}（{currrentRanking.scopeDate}）
        </Box>
        {currrentRanking.ranking.length === 0 ? (
          <Box>
            <Box className="mx-8 my-16 text-base text-black-2">
              フォローされた企業がありません。
            </Box>
            <WhiteBgButton
              className="my-8 w-full"
              onClick={() => {
                location.href = '/mypage/follow_companies';
              }}
              label="フォロー企業を見る"
              isPrimary={false}
              isLarge={false}
            />
          </Box>
        ) : (
          <>
            {currrentRanking.ranking.map((company, index) => (
              <CommonLink
                className="flex w-[90%] flex-row items-center border-b-2 px-8"
                href={`${COMPANIES}/${company.companyID}`}
                key={index}
                options={{target: '_blank'}}
              >
                {RenderRankingNumber(company.rank)}
                <Box className="ml-8 w-[55%] text-base text-primary-2">
                  {company.companyName}
                </Box>
                <Box className="ml-16 w-[50px] text-right text-base text-black-2">
                  +{company.diff}
                </Box>
                <MdArrowForwardIos color={COLOR_BLACK_2} />
              </CommonLink>
            ))}
          </>
        )}
      </Box>
      {currnentUrl !== '/company' && currrentRanking.ranking.length > 5 && (
        <Box
          className="mt-16 flex w-full cursor-pointer flex-row items-center justify-center text-base text-primary-2"
          onClick={() => {
            location.href = '/company';
          }}
        >
          もっと見る
        </Box>
      )}
    </Card>
  );
};
