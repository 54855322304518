import {useEffect, useMemo, useRef, type PropsWithChildren} from 'react';
import Image from 'next/image';
import Head from 'next/head';
import {Box} from '@mantine/core';
import {Carousel} from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import {useAuth} from '../../../../core/usecases/useAuth';
import {GoogleAdBannerName} from '../../../../config/constants/googleAdBanner';
import {useGoogleAdBanner} from '../../../../shared/services/useGoogleAdBanner';
import {Header} from '../../../../shared/components/Header';
import isAppWebView from '../../../../config/isAppWebView';
import {EmailConfirmNoticeForPC} from '../../../../shared/components/EmailConfirmNoticeForPC';
import {EmailConfirmNoticeForSP} from '../../../../shared/components/EmailConfirmNoticeForSP';
import {Sidebar} from '../../../../shared/components/Sidebar';
import {useMediaQuery} from '../../../../shared/services/useMediaQuery';
import {GoogleAdBanner} from '../../../../shared/components/Banner/GoogleAdBanner';
import {Footer} from '../../../../shared/components/Footer';
import {useGetMypageJackBannerSetting} from '../../../../core/repositories/advertisementRepository';
import {useTopCompanyList} from '../../../../core/repositories/companyRepository';
import {CommonLink} from '../../../../shared/components/CommonLink';
import {maintenanceEndTime} from '../../../../config/constants/maintenance';
import {MaintenanceTop} from '../../../maintenance/components/MaintenanceTop';
import {Announcement} from '../../../maintenance/components/Annoucement';
import {LoggedInTopRightColumn} from './LoggedInTopRightColumn';

export const LoggedInTopLayout = ({children}: PropsWithChildren) => {
  const {isPC} = useMediaQuery();
  const {accessToken} = useAuth();
  const googleAdBannerNames = [
    GoogleAdBannerName.B_RECTANGLE,
    GoogleAdBannerName.B_RECTANGLE_BOTTOM,
    GoogleAdBannerName.RICH_DESKTOP_HEADER,
    GoogleAdBannerName.RICH_DESKTOP_BODY,
  ];
  const {setShouldRefetch} = useGoogleAdBanner(
    googleAdBannerNames,
    accessToken,
  );
  const {data} = useGetMypageJackBannerSetting({
    accessToken,
    queryParams: {is_mobile: false},
  });
  const {data: companies} = useTopCompanyList({
    accessToken,
    queryParams: {
      limit: 20,
      is_only_top: true,
    },
  });
  const allCompanies = companies?.ok ? companies.value.companies : [];

  const autoplay = useRef(Autoplay({delay: 2000}));
  const plugins = useMemo(() => [autoplay.current], []);

  // インジケーターに企業のロゴ画像をセット
  const setIndicatorBackgroundImages = () => {
    if (!allCompanies) return;
    const indicators: NodeListOf<HTMLElement> = document.querySelectorAll(
      '.mantine-Carousel-indicator',
    );

    for (const [index, indicator] of indicators.entries()) {
      if (allCompanies[index]) {
        indicator.style.backgroundImage = `url(${allCompanies[index]?.logo_url})`;
      }
    }
  };

  useEffect(() => {
    if (data?.ok && data.value.isActivated) {
      setShouldRefetch(true);
    }
  }, [data]);

  useEffect(() => {
    if (allCompanies.length > 0) {
      setIndicatorBackgroundImages();
    }
  }, [
    allCompanies,
    document.querySelectorAll('.mantine-Carousel-indicator').entries(),
  ]);

  const maintenanceNow = new Date();
  const isMaintenance = maintenanceEndTime > maintenanceNow;

  // アナウンスを表示する or しない
  const isAnnouncement = false;

  return (
    <>
      <Head>
        <style>
          {/* MantineのCarouselのコンポーネントから展開されるインジケータを企業ロゴの形で表示させる */}
          {`
        div.mantine-Carousel-indicators {
          padding-top: 4px;
          gap: 0px !important;
          position: sticky;
          display: flex;
          flex-wrap: wrap !important;
          width: 100%;
          height: 120px;
          button {
            margin-right: 2px;
            margin-left: 2px;
            display: block;
            width: 60px !important;
            height: 50px !important;
            padding: 0 !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
          }
        }
        `}
        </style>
      </Head>
      {!isAppWebView() && <Header />}
      <Box className="bg-background-1 pt-16">
        {isAnnouncement && <Announcement />}
        {isPC && (
          <>
            {isMaintenance && <MaintenanceTop />}
            <div className="w-[1200px] mx-auto  bg-background-1">
              <GoogleAdBanner
                googleAdBannerName={GoogleAdBannerName.RICH_DESKTOP_HEADER}
              />
            </div>
          </>
        )}
        <main className="flex min-h-screen py-12 pc:bg-background-1 pc:py-32">
          <Box className="mx-auto flex w-[1200px] justify-between">
            <Box className="mx-auto my-0 max-w-[1200px] overflow-hidden">
              {isPC ? <EmailConfirmNoticeForPC /> : <EmailConfirmNoticeForSP />}
              <Box className="flex items-start gap-x-16">
                {isPC && <Sidebar className="max-w-[200px]" />}
                <Box className="w-full max-w-[664px]">
                  <div className="hidden pc:block">
                    {data?.ok && data.value.isActivated ? (
                      <GoogleAdBanner
                        googleAdBannerName={
                          GoogleAdBannerName.RICH_DESKTOP_BODY
                        }
                      />
                    ) : (
                      <>
                        {allCompanies.length > 0 && (
                          <Carousel
                            height={268}
                            slideSize={'100%'}
                            align={'start'}
                            loop
                            dragFree
                            withIndicators
                            styles={{
                              indicators: {
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                gap: '8px',
                              },
                              indicator: {
                                backgroundSize: 'cover',
                                borderRadius: '0',
                                width: '60px',
                                height: '50px',
                                border: 'none',
                              },
                            }}
                            plugins={plugins}
                            initialSlide={0}
                            onMouseEnter={autoplay.current.stop}
                            onMouseLeave={autoplay.current.reset}
                          >
                            {allCompanies.map((carousel, index) => (
                              <Carousel.Slide key={index}>
                                <CommonLink
                                  key={`carousel-${index}`}
                                  href={`company/${carousel.id}`}
                                  className="relative overflow-hidden"
                                >
                                  <Image
                                    src={carousel.background_image_url}
                                    width={isPC ? 664 : 375}
                                    height={isPC ? 248 : 156}
                                    alt={`caroucel-${index}`}
                                    className="object-cover"
                                  />
                                  <div className="absolute w-full bottom-0 bg-white bg-opacity-90 p-16">
                                    <p className="text-md text-black font-bold text-wrap  work-wrap line-clamp-2">
                                      {carousel.name}
                                    </p>
                                    <p className="text-black-1 text-sm">
                                      {carousel.catch_phrase}
                                    </p>
                                  </div>
                                </CommonLink>
                              </Carousel.Slide>
                            ))}
                          </Carousel>
                        )}
                      </>
                    )}
                  </div>
                  {children}
                </Box>
              </Box>
            </Box>
            {isPC && <LoggedInTopRightColumn accessToken={accessToken} />}
          </Box>
        </main>
      </Box>
      {!isAppWebView() && <Footer />}
    </>
  );
};
