import {
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  type UseInfiniteQueryResult,
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';
import {NotFoundError, type AppError, type Result} from '@packages/utils';
import {type z} from 'zod';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {defaultClient} from '../../lib/defaultClient';
import {
  GetPostListResponse,
  type PostListSchema,
  ColumnCategoriesSchema,
  ColumnPostListSchema,
  GetColumnCarouselList,
  GetPostResponse,
  RankingColumnListSchema,
  SpecialPostListSchema,
  type GetPreviewCodeResponse,
  GetPreviewCodeResponseSchema,
} from '../entities/columns/schema';
import {createPathWithQuery} from '../../shared/services/createPathWithQuery';
import {
  SPECIAL_POST_SLUGS,
  type ColumnType,
} from '../entities/columns/constants';
import {filterExistParams} from '../../shared/services/filterExistParams';

// GET /column/posts
export type GetColumnPostList = z.infer<typeof ColumnPostListSchema>;
export type ResultGetColumnList = Result<GetColumnPostList, AppError>;

type GetColumnPostListArgs = {
  version?: string;
  type?: ColumnType;
  category?: string;
  subCategory?: string;
  tags?: string[];
  title?: string;
  companyID?: number;
  page?: number;
  limit?: number;
};

const getColumnPostList = async ({
  version = DEFAULT_API_VERSION,
  type,
  category,
  subCategory,
  tags,
  title,
  companyID,
  page,
  limit,
}: GetColumnPostListArgs): Promise<ResultGetColumnList> => {
  const params: Partial<{
    [K in keyof GetColumnPostListArgs]: K extends 'tags' ? string[] : string;
  }> = {};

  if (title) {
    params.title = title;
  }

  if (type) {
    params.type = type;
  }

  if (category) {
    params.category = category;
  }

  if (subCategory) {
    params.subCategory = subCategory;
  }

  if (tags) {
    params.tags = tags;
  }

  if (companyID) {
    params.companyID = String(companyID);
  }

  if (page) {
    params.page = String(page);
  }

  if (limit) {
    params.limit = String(limit);
  }

  const path = createPathWithQuery(`/${version}/column/posts`, params, 'after');
  const result = await defaultClient.get(path, ColumnPostListSchema);

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useGetColumnPostList = ({
  version = DEFAULT_API_VERSION,
  type,
  category,
  subCategory,
  tags,
  title,
  companyID,
  page,
  limit,
}: GetColumnPostListArgs): UseQueryResult<ResultGetColumnList, AppError> => {
  return useQuery(
    [
      'getColumnPostList',
      {type, category, subCategory, tags, title, companyID, limit},
    ],
    async () =>
      getColumnPostList({
        version,
        type,
        category,
        subCategory,
        tags,
        title,
        companyID,
        page,
        limit,
      }),
  );
};

export type UseInfiniteGetColumnPostList = {
  version?: string;
  config?: UseInfiniteQueryOptions<
    ResultGetColumnList,
    AppError,
    ResultGetColumnList,
    ResultGetColumnList,
    [string, Omit<GetColumnPostListArgs, 'page'>]
  >;
  queryParam: Omit<GetColumnPostListArgs, 'page'>;
};

export const useInfiniteGetColumnPostList = ({
  version = DEFAULT_API_VERSION,
  queryParam: {type, category, subCategory, tags, title, companyID, limit = 10},
  config,
}: UseInfiniteGetColumnPostList): UseInfiniteQueryResult<
  ResultGetColumnList,
  AppError
> => {
  return useInfiniteQuery({
    queryKey: [
      'getColumnPostList',
      {type, category, subCategory, tags, title, companyID, limit},
    ],
    queryFn: async ({pageParam = 1}) =>
      getColumnPostList({
        version,
        type,
        category,
        subCategory,
        tags,
        title,
        companyID,
        page: pageParam,
        limit,
      }),
    getNextPageParam(lastPage, allPages) {
      return lastPage.ok && lastPage.value.posts.length < limit
        ? undefined
        : allPages.length + 1;
    },
    ...config,
  });
};

type getPostListByIDsArgs = {
  columnIds: string[];
  version?: string;
};

// 記事IDを指定して記事一覧を取得
export const getPostListByIDs = async ({
  columnIds,
  version = DEFAULT_API_VERSION,
}: getPostListByIDsArgs): Promise<Result<GetColumnPostList, AppError>> => {
  const path = createPathWithQuery(
    `/${version}/column/posts`,
    {
      ids: columnIds,
    },
    'after',
  );

  const result = await defaultClient.get(path, ColumnPostListSchema);

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

// GET /column/posts/:columnId
export type IGetPost = {
  accessToken?: string;
  columnId: string;
  previewCode?: string;
  version?: string;
};

export type IGetPostList = {
  queryParams: {
    type?: string;
    category?: string;
    subCategory?: string;
    tags?: string[];
    q?: string;
    companyID?: number;
    page?: number;
    limit?: number;
  };
  version?: string;
};

export type GetPostReturnValueType = Result<GetPostResponse, AppError>;
export type GetPostListReturnValueType = Result<GetPostListResponse, AppError>;
export type EngineerColumn = z.infer<typeof PostListSchema>;

export const getPost = async ({
  accessToken,
  columnId,
  previewCode,
  version = DEFAULT_API_VERSION,
}: IGetPost): Promise<GetPostReturnValueType> => {
  if (accessToken) {
    defaultClient.setToken(accessToken);
  }

  const params = filterExistParams({previewCode});
  const path = createPathWithQuery(
    `/${version}/column/posts/${columnId}`,
    params,
  );
  const result = await defaultClient.get(path, GetPostResponse);

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export type IUseGetPost = {
  config?: UseQueryOptions<GetPostReturnValueType>;
} & IGetPost;

export const useGetPost = ({
  accessToken,
  columnId,
  previewCode,
  version = DEFAULT_API_VERSION,
  config,
}: IUseGetPost) => {
  return useQuery<GetPostReturnValueType>(['getPost', columnId], {
    ...config,
    async queryFn() {
      return getPost({
        accessToken,
        columnId,
        previewCode,
        version,
      });
    },
  });
};

export type GetSpecialPostArgs = {
  accessToken?: string;
  slug: string;
  previewCode?: string;
  version?: string;
};

export const getSpecialPost = async ({
  accessToken,
  slug,
  previewCode,
  version = DEFAULT_API_VERSION,
}: GetSpecialPostArgs): Promise<GetPostReturnValueType> => {
  if (
    !slug ||
    (!previewCode && !Object.values(SPECIAL_POST_SLUGS).includes(slug))
  ) {
    // 不正なslugでも正常にレスポンスが返ってきてしまうのでここでエラーハンドリングする
    throw new Error(NotFoundError);
  }

  if (accessToken) {
    defaultClient.setToken(accessToken);
  }

  const params = filterExistParams({previewCode});
  const path = createPathWithQuery(
    `/${version}/column/posts-special/${slug}`,
    params,
  );
  const result = await defaultClient.get(path, GetPostResponse);

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export type UseGetSpecialPost = {
  config?: UseQueryOptions<GetPostReturnValueType>;
} & GetSpecialPostArgs;

export const useGetSpecialPost = ({
  accessToken,
  slug,
  previewCode,
  version = DEFAULT_API_VERSION,
  config,
}: UseGetSpecialPost) => {
  return useQuery<GetPostReturnValueType>(['getSpecialPost', slug], {
    ...config,
    async queryFn() {
      return getSpecialPost({accessToken, slug, previewCode, version});
    },
  });
};

export type GetCompanyPagePostArgs = {
  accessToken?: string;
  companyId: number;
  slug: string;
  previewCode?: string;
  version?: string;
};

export const getCompanyPagePost = async ({
  accessToken,
  companyId,
  slug,
  previewCode,
  version = DEFAULT_API_VERSION,
}: GetCompanyPagePostArgs): Promise<GetPostReturnValueType> => {
  if (accessToken) {
    defaultClient.setToken(accessToken);
  }

  const params = filterExistParams({previewCode});
  const path = createPathWithQuery(
    `/${version}/column/posts-company-page/${companyId}/${slug}`,
    params,
  );
  const result = await defaultClient.get(path, GetPostResponse);

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export type UseGetCompanyPagePost = {
  config?: UseQueryOptions<GetPostReturnValueType>;
} & GetCompanyPagePostArgs;

export const useGetCompanyPagePost = ({
  accessToken,
  companyId,
  slug,
  previewCode,
  version = DEFAULT_API_VERSION,
  config,
}: UseGetCompanyPagePost) => {
  return useQuery<GetPostReturnValueType>(
    ['getCompanyPagePost', companyId, slug],
    {
      ...config,
      async queryFn() {
        return getCompanyPagePost({
          accessToken,
          companyId,
          slug,
          previewCode,
          version,
        });
      },
    },
  );
};

export const getPostList = async ({
  queryParams,
  version = DEFAULT_API_VERSION,
}: IGetPostList): Promise<GetPostListReturnValueType> => {
  const path = createPathWithQuery(
    `/${version}/column/posts`,
    {
      ...queryParams,
    },
    'after',
  );
  const result = await defaultClient.get(path, GetPostListResponse);
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

// GET /column/special-posts
export type GetSpecialPostList = z.infer<typeof SpecialPostListSchema>;
export type GetSpecialPostListResult = Result<GetSpecialPostList, AppError>;

export const getSpecialPostList = async ({
  version = DEFAULT_API_VERSION,
}: {
  accessToken?: string;
  version?: string;
} = {}): Promise<GetSpecialPostListResult> => {
  const result = await defaultClient.get(
    `/${version}/column/special-posts`,
    SpecialPostListSchema,
  );

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export type IUseGetPostList = {
  config?: UseQueryOptions<GetPostListReturnValueType>;
} & IGetPostList;

export const useGetPostList = ({
  queryParams,
  version = DEFAULT_API_VERSION,
  config,
}: IUseGetPostList) => {
  return useQuery<GetPostListReturnValueType>(['getPostList'], {
    ...config,
    async queryFn() {
      return getPostList({queryParams, version});
    },
  });
};

export type UseGetSpecialPostList = {
  config?: UseQueryOptions<GetSpecialPostListResult>;
} & {
  version?: string;
};

export const useGetSpecialPostList = ({
  version = DEFAULT_API_VERSION,
  config,
}: UseGetSpecialPostList) => {
  return useQuery<GetSpecialPostListResult>(['getSpecialPostList'], {
    ...config,
    async queryFn() {
      return getSpecialPostList({version});
    },
  });
};

// GET /column/categories
export type GetColumnCategories = z.infer<typeof ColumnCategoriesSchema>;
export type GetColumnCategoriesResult = Result<GetColumnCategories, AppError>;

export const getColumnCategories = async ({
  version = DEFAULT_API_VERSION,
}: {
  version?: string;
} = {}): Promise<GetColumnCategoriesResult> => {
  const result = await defaultClient.get(
    `/${version}/column/categories`,
    ColumnCategoriesSchema,
  );

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export type UseGetColumnCategories = {
  config?: UseQueryOptions<GetColumnCategoriesResult>;
} & {
  version?: string;
};

export const useGetColumnCategories = ({
  version = DEFAULT_API_VERSION,
  config,
}: UseGetColumnCategories) => {
  return useQuery<GetColumnCategoriesResult>(['getColumnCategories'], {
    ...config,
    async queryFn() {
      return getColumnCategories({version});
    },
  });
};

// GET /column/ranking
export type GetRankingColumnList = z.infer<typeof RankingColumnListSchema>;
export type GetRankingColumnListResult = Result<GetRankingColumnList, AppError>;

type GetRankingColumnListArgs = {
  version?: string;
  page?: number;
  limit?: number;
};

export const getRankingColumnList = async ({
  version = DEFAULT_API_VERSION,
  page,
  limit,
}: GetRankingColumnListArgs): Promise<GetRankingColumnListResult> => {
  const params: Record<string, string> = {};
  if (page) {
    params.page = String(page);
  }

  if (limit) {
    params.limit = String(limit);
  }

  const path = createPathWithQuery(`/${version}/column/posts-ranking`, params);
  const result = await defaultClient.get(path, RankingColumnListSchema);

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export type UseGetRankingColumnList = {
  config?: UseQueryOptions<GetRankingColumnListResult>;
} & GetRankingColumnListArgs;

export const useGetRankingColumnList = ({
  version = DEFAULT_API_VERSION,
  page,
  limit,
  config,
}: UseGetRankingColumnList) => {
  return useQuery<GetRankingColumnListResult>(['getRankingColumnList'], {
    ...config,
    async queryFn() {
      return getRankingColumnList({version, page, limit});
    },
  });
};

type UseInfiniteGetRankingColumnListArgs = {
  version?: string;
  queryParams: {
    limit?: number;
  };
  config?: UseInfiniteQueryOptions<
    GetRankingColumnListResult,
    AppError,
    GetRankingColumnListResult,
    GetRankingColumnListResult,
    [string, Omit<GetRankingColumnListArgs, 'page'>]
  >;
};

export const useInfiniteGetRankingColumnList = ({
  version = DEFAULT_API_VERSION,
  queryParams: {limit = 10},
  config,
}: UseInfiniteGetRankingColumnListArgs): UseInfiniteQueryResult<
  GetRankingColumnListResult,
  AppError
> => {
  return useInfiniteQuery({
    queryKey: ['getRankingColumnList', {limit}],
    queryFn: async ({pageParam = 1}) =>
      getRankingColumnList({version, page: pageParam, limit}),
    getNextPageParam(lastPage, allPages) {
      return lastPage.ok && lastPage.value.posts.length < limit
        ? undefined
        : allPages.length + 1;
    },

    ...config,
  });
};

// GET /column/carousel

export type GetColumnCarouselList = z.infer<typeof GetColumnCarouselList>;
export type GetColumnCarouselListResult = Result<
  GetColumnCarouselList,
  AppError
>;

type GetColumnCarouselListArgs = {
  version?: string;
};

export const getColumnCarouselList = async ({
  version = DEFAULT_API_VERSION,
}: GetColumnCarouselListArgs) => {
  const result = await defaultClient.get(
    `/${version}/column/carousel`,
    GetColumnCarouselList,
  );

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export type UseGetColumnCarouselList = {
  config?: UseQueryOptions<GetColumnCarouselListResult>;
} & GetColumnCarouselListArgs;

export const useGetColumnCarouselList = ({
  version = DEFAULT_API_VERSION,
  config,
}: UseGetColumnCarouselList) => {
  return useQuery<GetColumnCarouselListResult>(['getColumnCarouselList'], {
    ...config,
    async queryFn() {
      return getColumnCarouselList({version});
    },
  });
};

type GetPreviewCodeForPostArgs = {
  accessToken: string;
  columnId: string;
  version?: string;
};

export const getPreviewCodeForPost = async ({
  accessToken,
  columnId,
  version = DEFAULT_API_VERSION,
}: GetPreviewCodeForPostArgs): Promise<
  Result<GetPreviewCodeResponse, AppError>
> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.get(
    `/${version}/column/posts/${columnId}/preview-code`,
    GetPreviewCodeResponseSchema,
  );

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

type GetPreviewCodeForSpecialPostArgs = {
  accessToken: string;
  slug: string;
  version?: string;
};

export const getPreviewCodeForSpecialPost = async ({
  accessToken,
  slug,
  version = DEFAULT_API_VERSION,
}: GetPreviewCodeForSpecialPostArgs): Promise<
  Result<GetPreviewCodeResponse, AppError>
> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.get(
    `/${version}/column/posts-special/${slug}/preview-code`,
    GetPreviewCodeResponseSchema,
  );

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

type GetPreviewCodeForCompanyPagePostArgs = {
  accessToken: string;
  companyId: number;
  slug: string;
  version?: string;
};

export const getPreviewCodeForCompanyPagePost = async ({
  accessToken,
  companyId,
  slug,
  version = DEFAULT_API_VERSION,
}: GetPreviewCodeForCompanyPagePostArgs): Promise<
  Result<GetPreviewCodeResponse, AppError>
> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.get(
    `/${version}/column/posts-company-page/${companyId}/${slug}/preview-code`,
    GetPreviewCodeResponseSchema,
  );

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};
