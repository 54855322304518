import {WhiteBgButtonThin} from '@packages/ui';
import {type z} from 'zod';
import {
  type RecruitmentSchedulesScheme,
  type RecruitmentsSchema,
  type RecruitmentSchema,
  type RecruitmentType,
} from '../../../../../core/entities/recruitment/schema';
import {
  RecruitmentTagListItem,
  RecruitmentTypeListItem,
} from '../../../../recruitment/components/RecruitmentTag';
import {CommonLink} from '../../../../../shared/components/CommonLink';
import {convertDateToScheduleDateObj} from '../../../../../core/factories/recruitment/convertDateToScheduleDateObj';
import {ScheduleButton} from '../../../../recruitment/components/ScheduleButton';
import {useMediaQuery} from '../../../../../shared/services/useMediaQuery';
import {Schedules} from './Schedules';

type Props = {
  accessToken?: string;
  recruitments: z.infer<typeof RecruitmentsSchema>;
};

export const RecruitmentList: React.FC<Props> = ({
  accessToken = '',
  recruitments,
}: Props) => {
  return (
    <div>
      <div>
        {recruitments.map((recruitment) => {
          return (
            <RecruitmentComponent
              accessToken={accessToken}
              recruitment={recruitment}
            />
          );
        })}
      </div>
    </div>
  );
};

const RecruitmentComponent: React.FC<{
  accessToken: string;
  recruitment: z.infer<typeof RecruitmentSchema>;
}> = ({accessToken, recruitment}) => {
  const {isPC} = useMediaQuery();
  const isNotArchive = recruitment.category !== 'archive';

  return (
    <div className="mb-24 [&:nth-last-child(1)]:mb-0 pb-24 [&:nth-last-child(1)]:pb-0 border-b-[1px] border-line border-collapse [&:nth-last-child(1)]:border-b-0">
      <div className="flex pc:block">
        <div className={isPC ? `` : `w-[74%]`}>
          <ul className="flex flex-wrap pc:flex-nowrap w-full pc:w-auto">
            <li className="mr-8">
              <RecruitmentTypeListItem
                recruitmentType={recruitment.type as RecruitmentType}
              />
            </li>
            {recruitment.tags.map((tag) => {
              return (
                <li key={`tag-${tag.id}`} className="mr-8">
                  <RecruitmentTagListItem recruitmentTag={tag} />
                </li>
              );
            })}
            {recruitment.groupedJobTypes.map((jobType) => {
              return (
                <li key={`jobType-${jobType.id}`} className="mr-8">
                  <p className="bg-black-3 text-xs text-white py-2 w-[72px] text-center">
                    {jobType.name}
                  </p>
                </li>
              );
            })}
            <li className="mr-8">
              <p className="bg-black-3 text-xs text-white py-2 w-[72px] text-center">
                {recruitment.targetYear > 0
                  ? `${recruitment.targetYear}卒`
                  : '学年不問'}
              </p>
            </li>
          </ul>
          <div className="mt-12">
            <CommonLink
              className="text-[13px] pc:text-md text-black-1 pc:text-link-1 font-bold hover:opacity-80"
              href={recruitment.detailPageURL}
            >
              {recruitment.title}
            </CommonLink>
            <p className="text-sm text-black-3 mt-8">
              {recruitment.companies[0]?.name}
            </p>
          </div>
        </div>
        {!isPC && isNotArchive && (
          <div className="w-[26%] ml-auto">
            <img
              src={recruitment.companies[0]?.logoURLs['140x105']}
              alt={recruitment.companies[0]?.name}
              className="object-cover"
            />
          </div>
        )}
      </div>
      {isPC && recruitment.schedules.length > 0 && (
        <Schedules
          accessToken={accessToken}
          schedules={recruitment.schedules}
          recruitmentId={recruitment.id}
          isSp={false}
        />
      )}
      {!isPC && (
        <div>
          <WhiteBgButtonThin
            isPrimary={false}
            label={'募集詳細を見る'}
            onClick={() => {
              location.href = recruitment.detailPageURL;
            }}
            className="w-full mt-12"
          />
        </div>
      )}
    </div>
  );
};

const ScheduleComponent: React.FC<{
  accessToken: string;
  recruitmentId: number;
  schedule: RecruitmentSchedulesScheme;
}> = ({accessToken, recruitmentId, schedule}) => {
  const entryEnd = convertDateToScheduleDateObj(
    schedule.entryEnd,
    schedule.isEntryEndAllDay,
  );

  return (
    <li
      key={`schedule-${schedule.id}`}
      className="border-b-[1px] [&:nth-last-child(1)]:border-b-0 border-collapse border-line-1 pb-12 [&:nth-last-child(1)]:pb-0 mb-12 [&:nth-last-child(1)]:mb-0"
    >
      <div className="flex justify-between items-start">
        <p className="text-md text-black-1 font-bold w-[calc(100%-90px)] pr-12">
          {schedule.name}
        </p>
        <ScheduleButton
          accessToken={accessToken}
          scheduleId={schedule.id}
          isRegisteredInital={schedule.isCalendarRegistered}
          registeredCountInitial={schedule.calendarRegisteredCount}
        />
      </div>
      <div className="flex justify-end mt-12">
        <p className="text-notification-1 leading">
          <span className="text-xl">
            {entryEnd?.month}/{entryEnd?.day}
          </span>
          <span className="text-base ml-4">({entryEnd?.dayOfWeek})</span>
          <span className="text-base ml-4">
            {entryEnd?.time && entryEnd.time}
          </span>
          <span className="text-base ml-4">{schedule.applyMethod.label}</span>
        </p>
      </div>
    </li>
  );
};
