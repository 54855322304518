import {useMediaQuery} from '../../../../shared/services/useMediaQuery';
import {
  GetRecruitmentListResponse,
  type Recruitment,
} from '../../../../core/entities/recruitment/schema';
import {GoogleAdBannerName} from '../../../../config/constants/googleAdBanner';
import {useGoogleAdBanner} from '../../../../shared/services/useGoogleAdBanner';
import {GoogleAdBanner} from '../../../../shared/components/Banner/GoogleAdBanner';
import {maintenanceEndTime} from '../../../../config/constants/maintenance';
import {MaintenanceTop} from '../../../maintenance/components/MaintenanceTop';
import {Announcement} from '../../../maintenance/components/Annoucement';
import {FindColumnByKeyword} from './FindColumnByKeyword';
import {LogInGuideHeaderForPc} from './LogInGuideHeaderForPc';
import {LogInGuideHeaderForSp} from './LogInGuideHeaderForSp';
import {NoLoggedInCommunityList} from './NoLoggedInCommunityList';
import {NoLoggedInColumnList} from './Presenter/NoLoggedInColumnList';
import {NoLoggedInReportList} from './NoLoggedInReportList';
import {NoLoggedInReportIndustryList} from './NoLoggedInReportIndustryList';
import {CompanyListForNoLoggedIn} from './Presenter/CompanyListForNoLoggedIn';
import {RecruitmentListForNoLoggedIn} from './Presenter/RecruitmentListForNoLoggedIn';
import {NoLoggedInEventGuide} from './Presenter/NoLoggedInEventGuide';

/**
 * 未ログイントップページコンテンツ
 */
export const NoLoggedInTop: React.FC = () => {
  const {isPC} = useMediaQuery();
  const googleAdBannerNames = [GoogleAdBannerName.RICH_DESKTOP_HEADER];
  useGoogleAdBanner(googleAdBannerNames, '');
  const maintenanceNow = new Date();
  const isMaintenance = maintenanceEndTime > maintenanceNow;

  // アナウンスを表示する or しない
  const isAnnouncement = false;

  return (
    <div className="w-full pc:w-[1200px]">
      {isAnnouncement && <Announcement />}
      {isPC && (
        <>
          {isMaintenance && <MaintenanceTop />}
          <div className="w-[1200px] mx-auto  bg-background-1">
            <GoogleAdBanner
              googleAdBannerName={GoogleAdBannerName.RICH_DESKTOP_HEADER}
            />
          </div>
        </>
      )}

      <LogInGuideHeaderForPc />
      <LogInGuideHeaderForSp />
      <NoLoggedInColumnList />
      {isPC && <CompanyListForNoLoggedIn />}
      {isPC && <NoLoggedInEventGuide />}
      {isPC && (
        <div className="w-[1200px] mt-16 flex justify-between">
          <NoLoggedInCommunityList />
          <NoLoggedInReportIndustryList />
        </div>
      )}
      {!isPC && <NoLoggedInReportList />}
      {!isPC && <CompanyListForNoLoggedIn />}
      <RecruitmentListForNoLoggedIn />
      <FindColumnByKeyword />
    </div>
  );
};
